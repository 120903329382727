<template>
    <div class="no-logged">
        <div class="container no-logged__body">
            <div class="logo spacing-y--m">
                <AtomIcon name="logo-smart-order" width="150"/>
            </div>
            <div class="message spacing-bottom--m">
                <span>
                    {{ $t('NoLogged.message_home_page', { plattform_name: platForm }) }}
                </span>
                <a :href="$t('NoLogged.intersport_portal_link')">
                    {{ $t('General.intersport_portal_looged_out') }}
                </a>
            </div>
            <div class="image spacing-bottom--m">
                <img src="/images/no-logged.jpg" alt="no-logged" >
            </div>
        </div>
        <div class="footer">
            <div class="container footer__content">
                <span>
                    {{ $t('NoLogged.intersport_germany_eg', { currentYear: currentYear }) }}
                </span>
                <span>
                    <a href="#uc-banner-show">{{ $t('NoLogged.cookie_setting') }}</a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Index',
    setup() {
        // const storeName = integrations.bestit.configuration.store;

        const currentYear = computed(() => new Date().getFullYear());
        const platForm = computed(() => ('Vororder'));

        return {
            platForm,
            currentYear,
        };
    },
};
</script>

<style lang="scss" scoped>
.no-logged {
    height: 100vh;
    display: flex;
    flex-direction: column;

    &__body {
        flex: 1 1 auto;

        .message {
            span {
                @include helper-color(gray-4);
                @include helper-font-weight(medium);
                @include helper-font-size(3);
            }

            a {
                text-decoration: underline;

                @include helper-color(corporate-blue);
                @include helper-font-weight(bold);
                @include helper-font-size(3);
            }
        }
    }

    .container {
        padding-top: rem(20);
    }

    .image {
        img {
            max-height: rem(727);
            display: block;
        }
    }

    .footer {
        flex: 0 1 40px;

        @include helper-color-bg(corporate-blue);

        .container {
            padding-top: 0;
        }

        &__content {
            height: 100%;
            font-size: fs(12);
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include helper-color(white);

            a {
                @include helper-color(white);
            }
        }
    }
}
</style>
