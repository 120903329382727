<template>
    <div v-if="isAuthenticated" class="layout-wrap">
        <div class="layout">
            <OrganismAppHeader />
            <slot />
            <OrganismAppFooter />
        </div>

        <OrganismMatrixModal />
        <MoleculeFlashMessages />
        <MoleculeToasts />
        <MoleculeTransfers />
    </div>
    <div v-else-if="showLoggedOut" class="layout">
        <OrganismLoggedOutLandingPage />
    </div>
</template>

<script setup lang="ts">
const showLoggedOut = ref(false);
const { loginFailed, loginPending, isAuthenticated } = useUser();
const config = useRuntimeConfig();
const toasts = useToasts();
const { userData, getUserData } = useUser();
const { $userFlow } = useNuxtApp();

const redirectTo = (event: Event) => {
    if ('detail' in event && typeof event.detail === 'string' && event.detail.length) {
        navigateTo(event.detail?.toString());
    }
};

onMounted(async() => {
    window.addEventListener('redirectTo', redirectTo);

    if (isAuthenticated.value) {
        await getUserData();

        if (!userData.value?.id) {
            toasts.add('LOAD_ERROR');

            return;
        }

        if (config.public.userCentricsIsActive) {
            useUsercentricsCookieConsents();
        }

        $userFlow.loadUserFlow();

        return;
    }

    if (!loginPending.value && loginFailed.value) {
        if (config.public.intersportPortalUrl && config.public.intersportPortalUrl !== '') {
            showLoggedOut.value = false;

            return;
        }
    }

    if (config.public.intersportPortalUrl && config.public.intersportPortalUrl !== '') {
        showLoggedOut.value = false;
        location.replace(config.public.intersportPortalUrl);
    } else {
        showLoggedOut.value = true;
    }
});

onBeforeUnmount(() => {
    window.removeEventListener('redirectTo', redirectTo);
});
</script>

<style lang="scss" scoped>
.layout {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.layout-wrap {
    height: 100%;
}

.main-content {
    flex: 1;
}
</style>
